<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title"> {{title}}</span>
                <!-- span class="head__sub-title">&nbsp;/&nbsp;{{subtitle}}</span -->
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <section class="section__container container container_active separator-when-scrolling plr__12 ptb__12">
                <div class="field-group field-group_width_400 mb__16">
                    <label class="field-group__label" for="group-name">{{ $t('Group name') }}</label>
                    <input class="field-group__input" type="text" id="group-name" name="group-name" value="" placeholder="Enter name">
                </div>

                <div class="multiple-choice multiple-choice_columns_4">
                    <div class="multiple-choice__head">
                        <input type="text" :placeholder="$t('text.Search')" class="multiple-choice__search-input mr__8">
                        <button class="button button_view_base button_theme_neutral button_size_md">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox_all" /></span> </span>
                            {{ $t('Select all') }}
                        </button>
                    </div>
                    <div class="multiple-choice__wrap">
                        <ul class="multiple-choice__list">
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">Carlos Netradyne</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">Dash Cam Guy</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DE_369</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">Demo_Karzo</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_2085</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_2095</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_ELD_2018</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_Volvo_2018</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">Carlos Netradyne</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">Dash Cam Guy </span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DE_369</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">Demo_Karzo</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_2085</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_2095</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_ELD_2018</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_Volvo_2018</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">Demo_Karzo</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_2085</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_2095</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_ELD_2018</span>
                                    </label>
                                </div>
                            </li>
                            <li class="multiple-choice__item">
                                <div class="checkbox-control-native">
                                    <label class="checkbox-control-native__label">
                                        <input class="checkbox-control-native__checkbox" type="checkbox" name="switch" checked="">
                                        <span class="checkbox-control-native__box"></span>
                                        <span class="checkbox-control-native__text">DNG_Volvo_2018</span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{action}} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "GroupEdit",
        props: [
            'groupId',
        ],
        components: {
            //'':    () => import('./'),
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsCount",
            ]),
            title(){
                return (this.unitId>0) ? this.unit.name : 'Creating a new group'
            },
            subtitle(){
                let selected = this.components.find(c => { return c.component === this.component})
                return selected.subtitle
            },
            action(){
                return (this.unitId>0) ? this.$t('buttons.Save') : this.$t('buttons.Create')
            },
        },
        methods: {
            ...mapActions([
                "setSectionWidget",
            ]),
            onCancel(){
                this.$emit('close-dialog')
            },
            onSave(){
                this.$emit('close-dialog')
            }
        },
        created() {
        },
        updated() {
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>